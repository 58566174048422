<template>
  <b-row>
    <b-col v-if="type" :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-md-6 col-lg-4'">
     <b-card no-body :class="`${is_detail_network ? 'border-box-analytics' : ''} text-center`">
        <b-card-header>
          <h4 class="mb-0">
            {{$t('instagram.audienceGenres')}}
          </h4>
        </b-card-header>
        <component
        :is="apex_charts"
        height="235"
        class="cursor-pointer"
        :options="genders"
        :series="series_gender"
        :key="change_language"
        >
        </component>
        <b-row class="text-center mx-0">
          <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
            <b-card-text class="text-muted mb-0">
              {{$t('instagram.female')}}

            </b-card-text>
            <h3 class="font-weight-bolder mb-50">
              {{ percentage_female }}%
            </h3>
          </b-col>
          <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
            <b-card-text class="text-muted mb-0">
              {{$t('instagram.male')}}

            </b-card-text>
            <h3 class="font-weight-bolder mb-50">
              {{ percentage_male }}%
            </h3>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-md-6 col-lg-4'">
      <b-card no-body :class="`${is_detail_network ? 'border-box-analytics' : ''}`">
        <b-card-header>
          <b-card-title>
            {{$t('instagram.ageRange')}}
          </b-card-title>
        </b-card-header>
        <b-card-body class="pb-0">
          <div  v-if="type" class="d-flex justify-content-start mb-3">
            <div class="mr-2">
              <b-card-text class="mb-50">
                {{$t('instagram.female')}}

              </b-card-text>
              <h3 class="font-weight-bolder">
                <span style="color:#E75480">{{ percentage_female }}%</span>
              </h3>
            </div>
            <div class="mr-2">
              <b-card-text class="mb-50">
                {{$t('instagram.male')}}

              </b-card-text>
              <h3 class="font-weight-bolder">
                <span style="color:#5cb3ff">{{ percentage_male }}%</span>
              </h3>
            </div>
          </div>
          <component :is="apex_charts" class="cursor-pointer" type="line" height="200" :options="chartOptions" :series="series" :key="change_language"></component>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BCardText  } from 'bootstrap-vue'
import { numberFormat } from '@/libs/utils/formats'
import { getSeries } from '@/libs/utils/series_graphs'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
  },
  props: {
    graphData: {
      required: true
    },
    type: {
      type: Boolean,
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      change_language: false,
      default: this.graphData.gender_age ? this.graphData.gender_age[0].value : '',
      percentage_male: numberFormat(this.graphData.gender_age[0].value),
      percentage_female: numberFormat(this.graphData.gender_age[1].value),
      series_gender: [
        parseFloat(numberFormat(this.graphData.gender_age[0].value)),
        parseFloat(numberFormat(this.graphData.gender_age[1].value))
      ],
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: `${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      genders: {
        labels: [`${this.$t('instagram.male')}`,`${this.$t('instagram.female')}`],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: ['#5cb3ff', '#E75480'],
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: `${this.$t('instagram.male')}`,
                  formatter: () => { return `${this.percentage_male}%` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      series: [{name: `${this.$t('instagram.male')}`, data: []}, {name: `${this.$t('instagram.female')}`, data: []}],
      apex_charts: null,
    }
  },
  methods: {
    getGenderAge () {
      this.chartOptions.xaxis.categories = this.graphData.gender_age[0].by_age_group.map(item => {
        return item.group.replace('age', '')
      })
      this.chartOptions.xaxis.categories.pop()
      this.graphData.gender_age.forEach((item, index) => {
        this.series[index].data =  item.by_age_group.map(item => { return item.value })
        this.series[index].data.pop()
      })
    },
    getData () {
      this.chartOptions.xaxis.categories = Object.keys(this.graphData.audience_age_gender.data)
      this.series[0].data = getSeries(this.getGender(this.graphData.audience_age_gender.data), 'male').pop()
      this.series[1].data = getSeries(this.getGender(this.graphData.audience_age_gender.data), 'female').pop()
    },
    getGender(data) {
      const obj = []
      const keys = Object.keys(data)
      for (let index = 0; index < keys.length; index++) {
        obj.push({male: data[keys[index]].male, female: data[keys[index]].female})
      }
      return obj
    }
  },
  created () {
    if (this.type) {
      this.getGenderAge()
    } else {
      this.getData()
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  watch: {
    '$i18n.locale'() {
      this.change_language = !this.change_language;
      this.genders.labels =  [`${this.$t('instagram.male')}`,`${this.$t('instagram.female')}`];
      this.genders.plotOptions.pie.donut.labels.total.label = `${this.$t('instagram.male')}`;
      this.series[0].name =  `${this.$t('instagram.male')}`;
      this.series[1].name =  `${this.$t('instagram.female')}`;
      this.chartOptions.xaxis.title.text = `${this.$t('instagram.ageRange')}`;
    }
  },
}
</script>
